import './App.css';
import React, { useEffect, useRef, useState } from 'react';
import chests from "./chests.json"

function App() {

  const page1Ref = useRef(null);
  const page2Ref = useRef(null);
  const currentPageRef = useRef(1);

  const [chestCursor, setChestCursor] = useState(0)

  const handleScrollTo = (elementId) => {
    document.getElementById(elementId).scrollIntoView({ behavior: 'smooth' });
  };

  const nextChest = () => {
    chestCursor === chests.length - 1 ? setChestCursor(0) : setChestCursor(chestCursor+1)
  }

  const prevChest = () => {
    chestCursor === 0 ? setChestCursor(chests.length - 1) : setChestCursor(chestCursor-1)
  }

  useEffect(() => {
    const handleWheel = (event) => {
      if (event.deltaY > 0 && currentPageRef.current === 1) {
        page2Ref.current.scrollIntoView({ behavior: 'smooth' });
        currentPageRef.current = 2;
      } else if (event.deltaY < 0 && currentPageRef.current === 2) {
        page1Ref.current.scrollIntoView({ behavior: 'smooth' });
        currentPageRef.current = 1;
      }
    };

    window.addEventListener('wheel', handleWheel);
    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, []);

  return (
    <div>
      <div ref={page1Ref} id="page1" className="page1">
        <img className={"kclogo"} alt={"kings-chests-logo"} src={"/images/kclogo.png"} />
        <img width={150} alt={"chestimg"} src={"/images/chestOpen.png"} />
        <div className={"chestCursor"}>{chestCursor+1}/{chests.length}</div>
        <div className={"gamebox"}>
          <div className={"chestStats"}>
            <div className={"stat"}><span className={"statKey"}>Name</span><span className={"statValue"}>{chests[chestCursor].name}</span></div>
            <div className={"stat"}><span className={"statKey"}>Players</span><span className={"statValue"}>{chests[chestCursor].players}</span></div>
            <div className={"stat"}><span className={"statKey"}>Attempt</span><span className={"statValue"}>{chests[chestCursor].attempt} ETH</span></div>
            <div className={"stat"}><span className={"statKey"}>Success</span><span className={"statValue"}>{chests[chestCursor].success} ETH</span></div>
          </div>
          <div className={"controlz"}>
              <div onClick={()=>prevChest()} className={"controlzBtn seekBtn"}>{"<"}</div>
              <a className={"controlzBtn playBtn"} target={"_blank"} rel={"noreferrer"} href={chests[chestCursor].link}>Play</a>
              <div onClick={()=>nextChest()} className={"controlzBtn seekBtn"}>{">"}</div>
          </div>
        </div>
        <div onClick={() => handleScrollTo('page2')} className="scrollDownBtn">
          <div className={"scrollLabel"}>How To Play</div>
          <img className={"scrollBtnImg"} height={50} alt={"downButton"} src={"/images/down.png"} />
        </div>
      </div>
      <div ref={page2Ref} id="page2" className="page2">
        <div onClick={() => handleScrollTo('page1')} className="scrollUpBtn">
          <img className={"scrollBtnImg"} height={50} alt={"downButton"} src={"/images/up.png"} />
          <div className={"scrollLabel"}>Chests</div>
        </div>
        <div className={"howToPlay"}>How To Play</div>
        <div className={"gameDesc"}>
          King's Chests is a player vs player game running on the Base Layer 2 blockchain.<br/>
          Every chest has a maximum number of players that can partcipate in the game.
          Each player will make an attempt to open the King's Chest.<br/>
          Attempting to open the King's Chest will cost players a fixed amount of ETH.<br/>
          Once the maximum number of players has been reached, one lucky player is randomly selected to open the chest.<br/>
          The winner receives the total value of the chest, while the losers are rewarded for their efforts.
        </div>
      </div>
    </div>
  );
}

export default App;
